@import "variables";

html, body {
    height: 100%;
}

body {
    margin: 0;
}

.splash {
    text-align: center;
    margin: 10% 0 0 0;
    box-sizing: border-box;

    .message {
        font-size: 72px;
        line-height: 72px;
        text-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
        text-transform: uppercase;
        font-family: Arial, Helvetica, sans-serif;
    }

    .fa-spinner {
        text-align: center;
        display: inline-block;
        font-size: 72px;
        margin-top: 50px;
        color: $ma-blue;
    }
}

.page-host {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(100% - #{$navbar-height});
}

.year-form {
    width: 150px !important;
    margin: 8px;
}

@media print {
    .page-host {
        position: absolute;
        left: 10px;
        right: 0;
        top: 50px;
        bottom: 0;
        overflow-y: inherit;
        overflow-x: inherit;
    }
}

section {
    margin: 0 20px;
}

.navbar-nav {
    li.loader {
        margin: 12px 0 0 0px;
    }

    li.user {
        margin-right: 20px;
        border-left: 2px solid $navbar-font-color;
    }
}

.navbar {
    &.navbar-shadow {
        box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.24), 0 0px 12px 0 rgba(0, 0, 0, 0.19);
    }

    &.navbar-default {
        background-color: $navbar-color;

        .navbar-nav > li > a {
            color: $navbar-font-color;
            font-weight: bold;
        }

        .active > a,
        .active > a:hover,
        .active > a:focus {
            color: $navbar-active-font-color !important;
        }

        .dropdown-menu > .active > a,
        .dropdown-menu > .active > a:hover,
        .dropdown-menu > .active > a:focus {
            color: $navbar-active-dropdown-font-color !important;
            background-color: $navbar-active-dropdown-background-color !important;
        }
    }
}

.contacts-view,
.contributions-view,
.base-contributions-view,
.factor-view,
.votes-view {
    height: 100%;
    margin: $contacts-main-margin;

    .top {
        height: calc(60% - #{$contacts-main-margin * 2});
        margin-bottom: 10px;
    }

    .bottom {
        height: calc(40% - #{$contacts-main-margin * 2});
    }

    .complete {
        height: calc(100% - #{$contacts-main-margin * 2});
    }
}

.factor-view {
    overflow-y: auto;
}

.contributions-view,
.base-contributions-view,
.votes-view {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 15px;
    padding-right: 15px;

    .table-title {
        h3 {
            display: inline-block;
        }

        a {
            margin-left: 15px;
        }
    }

    .contribution-table {
        height: calc(100% - #{$contribution-table-height-offset});
    }

}

.contributions-table-title {

}

.invoice-number-dialog {
    .number-error {
        font-weight: bold;
        color: red;
    }
}

.company-invoices-dialog,
.combination-editing-dialog {
    min-width: 450px;
}

.contacts-nav-bar {
    margin: 10px 15px 10px 15px;
    height: $button-bar-height;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.grid-view-header {
    h3 {
        display: inline-block;
    }

    select.form-control {
        width: 200px;
        display: inline-block;
        float: right;
        margin-top: 15px;
    }
}

.calcfactor-elements {
    margin-top: 10px;
}

.mara-grid {
    .k-header,
    .k-grid-filter.k-state-active {
        background-color: $table-header-background-color !important;
        border-color: $table-header-border-color !important;
    }

    .k-grid-header-wrap {
        border-color: $table-header-border-color !important;
    }

    .k-grid-header .k-link {
        color: $table-header-font-color !important;
    }

    .k-grid-header {
        background-color: $table-header-background-color !important;
        border-color: $table-header-border-color;
    }
}
